import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MetaInfo from 'vue-meta-info';

Vue.use(MetaInfo);
Vue.config.productionTip = false
// main.js

// 引入 swiper.css
import "swiper/css/swiper.css";
import axios from './axios_config.js'
Vue.prototype.$axios = axios

new Vue({
  el: '#app',
	router,
  render: h => h(App),
  mounted(){
    document.dispatchEvent(new Event('render-event'))
  }
})
