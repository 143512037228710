import axios from 'axios'
axios.defaults.timeout = 5000;  //设置超时时间
axios.defaults.baseURL = 'https://app.jo-mann.cn'; //这是调用数据接口

axios.interceptors.request.use(
	config => {
		
		return config;
	},
	
)
axios.interceptors.response.use(
	
	response =>{
		return Promise.resolve(response)
	},
	error =>{
        
	}
)
export default axios